import "core-js/modules/es.number.constructor.js";

/**
 * 四舍五入，toFixed 是四舍六入五取偶
 * @param {Number} num     [待处理数字]
 * @param {Number} decimal [需要保留的小数位]
 */
export function roundNumber(num) {
  var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var n = Number(num);

  if (isNaN(n)) {
    return 0;
  }

  var p1 = Math.pow(10, decimal + 1);
  var p2 = Math.pow(10, decimal);
  return Math.round(n * p1 / 10) / p2;
}
/**
 * 格式化数值
 * @param {Number} num
 */

export function stringifyNumber(num) {
  var obj = {
    value: num,
    unit: ''
  };

  if (!num) {
    obj.value = 0;
    return obj;
  }

  num = Number(num);
  if (!num) return obj;

  if (num >= 10000000) {
    // obj.value = Math.floor(num / 100000) / 100
    obj.value = roundNumber(num / 1e7);
    obj.unit = '千万';
    return obj;
  }

  if (num >= 10000) {
    // obj.value = Math.floor(num / 100) / 100
    obj.value = roundNumber(num / 1e4);
    obj.unit = '万';
  }

  return obj;
}